<template>
  <div>
    <el-form :model="searchCondition" inline size="mini">
      <el-form-item>
        <el-date-picker
            v-model="searchCondition.year"
            type="year"
            format="yyyy年"
            value-format="yyyy"
            placeholder="选择年" clearable>
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <QuarterSelect :quarter.sync="searchCondition.quarter" size="mini" @change="handleQuery"/>
      </el-form-item>
      <el-form-item>
        <PerformanceGroupSelect :group-id.sync="searchCondition.group_id" :search-condition="groupSearch" @change="handleQuery"/>
      </el-form-item>
      <el-form-item>
        <ArtistSearch @handleSelect="selectKol"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery">查询</el-button>
        <el-tooltip class="item" effect="dark" content="【确认发布】且【核算完成】的绩效数据" placement="right">
          <el-button type="text" icon="el-icon-info"></el-button>
        </el-tooltip>
      </el-form-item>
    </el-form>

    <div>
      <RevenueHistoryPanel :data-list="dataList" :quarterly-months="quarterlyMonths"/>
      <!--      <el-table :data="dataList" height="500" border>-->
      <!--        <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>-->
      <!--        <el-table-column prop="group_id" label="分组" min-width="120" align="center">-->
      <!--          <template slot-scope="{row}"><span>{{ row.group_name }}</span></template>-->
      <!--        </el-table-column>-->
      <!--        <el-table-column label="月份" min-width="120" align="center">-->
      <!--          <template slot-scope="{row}">-->
      <!--            <span>{{ row.year }}年{{ row.month }}月</span>-->
      <!--          </template>-->
      <!--        </el-table-column>-->
      <!--        <el-table-column prop="nickname" label="红人昵称" min-width="120" align="center"></el-table-column>-->
      <!--        <el-table-column prop="platform_name" label="平台" min-width="100" align="center"></el-table-column>-->
      <!--        <el-table-column prop="income_type" label="收入分类" min-width="100" align="center"></el-table-column>-->
      <!--        <el-table-column prop="flag_value" label="目标值" min-width="100" align="center"></el-table-column>-->
      <!--        <el-table-column prop="real_value" label="完成值" min-width="100" align="center"></el-table-column>-->
      <!--        <el-table-column prop="created_at" label="创建时间" min-width="100" align="center"></el-table-column>-->
      <!--        <el-table-column prop="updated_at" label="修改时间" min-width="100" align="center"></el-table-column>-->
      <!--      </el-table>-->
    </div>

  </div>
</template>

<script>
import QuarterSelect from "@/pages/performance/components/QuarterSelect";
import PerformanceGroupSelect from "@/pages/performance/components/PerformanceGroupSelect";
import RevenueHistoryPanel from "@/pages/performance/components/RevenueHistoryPanel";
import ArtistSearch from "@/components/artist/ArtistSearch";

export default {
  name: "RevenueMap",
  components: {ArtistSearch, RevenueHistoryPanel, PerformanceGroupSelect, QuarterSelect},
  data() {
    return {
      searchCondition: {group_id: null, year: null, quarter: null, artist_id: null},
      dataList: [],
      pageData: {current_page: 1, page_size: 500},
      orderSort: {group_name: 'asc', artist_id: 'asc'},
      // quarterlyMonths: []
    }
  },
  computed: {
    groupSearch() {
      return {year: this.searchCondition.year, quarter: this.searchCondition.quarter}
    },
    quarterlyMonths() {
      let quarter = this.searchCondition.quarter
      return [quarter * 3 - 2, quarter * 3 - 1, quarter * 3];
    }
  },
  methods: {
    handleQuery() {
      this.dataList = []
      this.getList()
    },
    async getList() {
      let searchCondition = this.handleSearchCondition()
      let {list} = await this.$api.getHistoryListOfPerformanceRevenue(searchCondition)
      this.dataList = list
    },
    selectKol(item) {
      this.searchCondition.artist_id = item.id || ''
      this.handleQuery()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      condition.release_status = 'Y'

      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year * 1
      }
      if (this.searchCondition.quarter) {
        condition.quarter = this.searchCondition.quarter * 1
      }

      return condition
    },
  },
  created() {
    this.searchCondition.year = new Date().getFullYear() + ''
    this.searchCondition.quarter = this.$utils.getCurrentQuarter()
    // $months = [$quarter * 3 - 2, $quarter * 3 - 1, $quarter * 3];
  }
}
</script>

<style scoped>

</style>